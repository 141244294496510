import "./portfolio.scss"
import PortfolioList from "../portfolioList/PortfolioList"
import {useState} from "react"
import {Games, Simulations} from "../../data.js"
import { useEffect } from "react"

export default function Portfolio() {

  const [selected, setSelected] = useState("games")
  const [data, setData] = useState([])

  const list = [
    {
      id:"games",
      title:"Games",
    },
    {
      id:"simulations",
      title:"Simulations",
    },
  ]

  useEffect(()=>{
    switch(selected){
      case "games":
        setData(Games)
        break
      case "simulations":
        setData(Simulations)
        break
      default:
        setData(Games)
    }
  },[selected])

  return (
    <div className = "portfolio" id = "portfolio"> 
      <h1>Portfolio</h1>
      <ul>
        {list.map((item)=>(
        <PortfolioList 
        title={item.title} 
        active={selected===item.id}
        id = {item.id}
        setSelected = {setSelected}/>))}
      </ul>
      <div className="container">
      {data.map((d)=>(
          <div className = "item">
            <img 
              src = {d.img}
              alt = ""
            />
            <h3>{d.title}</h3>
          </div>
        ))}        
      </div>
    </div>
  )
}
