import "./intro.scss"
import {useEffect, useRef} from "react"
import {init} from "ityped"

export default function Intro() {

  const textRef = useRef()
  
  useEffect(()=>{
    init(textRef.current,{
      showCursor:true,
      backDelay:2000,
      startDelay:1000,
      backSpeed:60,
      strings:["Game Developer","Programmer"]
    })
  },[])
  return (
    <div className ="intro" id ="intro">
        <div className="left">
          <div className = "imgContainer">
          
            
            <img src="assets/me.png" alt=""/>
            
          
          </div>
        </div>
        <div className="right">
          <div className="wrapper">
            <h2>Hi There, I'm</h2>
            <h1>Alp Konak</h1>
            <h3>Self Taught <span ref = {textRef}></span></h3>
            
            
          </div>
          <a href="#portfolio">
            <img src="assets/down.png" alt="" />
          </a>
        </div>
    </div>
  )
}
