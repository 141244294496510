import "./contact.scss"
import {useState} from "react"

export default function Contact() {

  const [discordActive, setDiscordActive] = useState(false)
  const [mailActive, setMailActive] = useState(false)
  const [phoneActive, setPhoneActive] = useState(false)

  return (
    <div className = "contact" id ="contact">
      
        <ul>
          <li><h3>Contact</h3></li>
          <li><a className = "logo" href = "https://www.instagram.com/alp_konak_/?hl=tr">Instagram</a></li>
          <li className = {discordActive?"discord active":"discord"} onClick = {()=>{setDiscordActive(!discordActive)}}>{!discordActive?"Discord":"MadNinja#8893"}</li>
          <li className = {mailActive?"mail active":"mail"} onClick = {()=>{setMailActive(!mailActive)}}>{!mailActive?"Mail":"ninjakonak@gmail.com"}</li>
          <li className = {phoneActive?"phone active":"phone"} onClick = {()=>{setPhoneActive(!phoneActive)}}>{!phoneActive?"Phone":"+90 0530 077 73 06"}</li>
          <li><a className = "logo" href = "https://github.com/ninjakonak">Github</a></li>
        </ul>
    </div>
  )
}
