
export const Games = [
    {
        id: 1,
        title: "Red Velvet (In Development)",
        img: "portfolio-assets/RedVelvetSquare.png",
    },
    {
        id: 2,
        title: "Dodge It",
        img: "portfolio-assets/DodgeIt.png",
    },
    {
        id: 3,
        title: "Meteor Destroyer",
        img: "portfolio-assets/MeteorDestroyer.png",
    },
    {
        id: 4,
        title: "Fake Undertale",
        img: "portfolio-assets/FakeUndertale.png",
    },
    {
        id: 5,
        title: "Level Editor for Red Velvet (In Development)",
        img: "portfolio-assets/LevelEdit.png",
    },
]

export const Simulations = [
    {
        id: 1,
        title: "N-Body simulation",
        img: "portfolio-assets/N-Body.png",
    },
    {
        id: 2,
        title: "Conway's Game of Life simulation",
        img: "portfolio-assets/GameOfLife.png",
    }
]